.root {
  margin: 0 0px 0 0;

  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  @media (max-width:767px) {
    padding: 0 0px 0 0;
  }
  &:hover {
    text-decoration: none;
  }
}

.cardroot{
  &:hover{
    text-decoration: none;
  }
}

.cardroot{
  margin: 0 40px 0 0;
  display: block;
  @media (max-width: 767px) {
    margin: 0 10px 0 0;
  }
}

.borderRadius {
  border-radius: 10px;
  background: #fff;
  box-shadow: 8px 3px 12px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.skills {
  padding: 24px 14px;
  background: #fcf6eb;
  display: flex;
  gap: 12px;
  max-width: 100%;
  overflow: auto;
  max-height: 82px;
  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f9d3c7;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffaa8c;
  }
  & > span {
    border-radius: 100px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 18px;
    color: #4A4A4A;
    white-space: nowrap;
  }
}

.cardContent {
  display: flex;
  gap: 21px;
  align-items: center;
  @media (max-width: 767px) {
    gap: 10px;
  }
}

.cardRightContent {
  padding: 18px 17px 0 0;
  & h4 {
    color: #2f2d2b;
    font-family: Source Serif 4;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    margin: 0;
  }
  & h5 {
    color: #2f2d2b;
    font-family: Source Serif 4;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.28px;
    margin: 0;
  }
  & ul {
    margin-top: 9px;
    list-style: disc;
    margin-left: 20px;
    margin-top: 0px;
  }
  & li {
    color: #2f2d2b;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    padding: 0;
    letter-spacing: 0.28px;
  }
}

.cardImg {
  border-radius: 10px 50px 0px 0px;
  max-width: 127px;
  width: 127.719px;
  height: 163.938px;
  position: relative;
  @media (max-width: 767px) {
  min-width: 100px;
  width: 100px;
  height: 140px;
  }
  & img {
    width: 100%;
    height: 163.938px;
    border-radius: 10px 50px 0px 0px;
    @media (max-width: 767px) {
      min-width: 100px;
      width: 100px;
      height: 140px;
      }
  }
}

.price {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.305px;
  margin: 10px 0 5px;
  padding: 0;
}

.cardType {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
}