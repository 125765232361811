@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  transition: 500ms linear;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  width: 127px;
  border-radius: 10px 50px 0px 0px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%) inset;
  overflow: hidden;
  min-height: 163px;
  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  & > div {
    height: 100%;
    & img {
      height: 100%;
      pointer-events: none;
    }
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 12px 0 5px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 5px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.305px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  color: #4a4a4a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingCardWrapper {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 25%);
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.listingBox {
  display: flex;
  gap: 21px;
  height: auto;
  min-height: 163px;
  align-items: flex-start;

  & .cardData {
    flex-grow: 1;
    padding: 19px 0 11px 0;

    & .mainTitle {
      color: #2f2d2b;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.4px;
      font-family: 'Source Serif 4', serif;
      text-overflow: ellipsis;
      text-wrap: wrap;
      overflow: hidden;
    }

    & .designation {
      color: #2f2d2b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.28px;
      font-family: 'Source Serif 4', serif;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .listingList {
      & .listItem {
        color: #2f2d2b;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: 0.28px;
        position: relative;
        padding-left: 10px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          background-color: #2f2d2b;
          left: 2px;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50px;
        }
      }
    }
  }
}

.listingBottomBox {
  border-radius: 0px 0px 10px 10px;
  background: #fcf6eb;
  display: flex;
  /* flex-wrap: wrap; */
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 11px;
  gap: 12px;
  max-height: 72px;
  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f9d3c7;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffaa8c;
  }
  & span {
    border-radius: 100px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 1px 2px 2px 0px rgb(0 0 0 / 25%) inset;
    padding: 5px 15px;
    height: 30px;
    color: #4a4a4a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
}
.companycard {
  max-width: 366px;
  width: 100%;
  height: 100%;
  /* display: block; */
  & .aspectRatioWrapper {
    margin-left: 16px ;
  
    width: 75.044px ;
    min-height: 100.044px ;
    border-radius: 4px;
    transition: 500ms linear;
    transform: unset !important;
    position: relative;
    background: transparent;
    box-shadow: none;
    & >div{
      top: 16px;
      position: absolute;
      left: 0;
      right: 0;
      background: var(--colorGrey100);
      bottom: 0;
    
    }
  }
  & .rootForImage{
    /* height: auto; */
  }
  & .listingCardWrapper:hover .aspectRatioWrapper{
    margin-left: 0px ;
    margin-top: 0px ;
    border-radius: 10px 50px 0px 0px;
    width: 134px;
    height: 170px !important;
    min-height: 170px !important;
    max-height: 170px !important;
    overflow: hidden;
    transform: unset !important;
    & >div{
      top: 0px;
      position: absolute;
      left: 0;
      right: 0;
      background: var(--colorGrey100);
      bottom: 0;
    
    }
 
  }
  & .listingCardWrapper:hover .listingBottomBox{
    background: #666241;
  }
}
